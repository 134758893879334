
import { ref, computed, onMounted } from "vue";
import { templateRef } from "@vueuse/core";

export default {
  props: {
    dataProvider: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const tpl_carouselMedia = templateRef("tpl_carouselMedia");
    const tpl_carouselText = templateRef("tpl_carouselText");
    const swiperMedia = ref(null);
    const swiperText = ref(null);
    const currentPage = ref(1);

    const items = ref(props.dataProvider.items);

    onMounted(() => {
      swiperMedia.value = new Swiper(tpl_carouselMedia.value, {
        loop: false,
        allowTouchMove: items.value.length > 1,
        grabCursor: items.value.length > 1,
        slidesPerView: 1,
        speed: 600,
        parallax: true,
      });

      swiperText.value = new Swiper(tpl_carouselText.value, {
        loop: false,
        parallax: true,
        allowTouchMove: items.value.length > 1,
        grabCursor: items.value.length > 1,
        slidesPerView: 1,
        speed: 600,
        on: {
          slideChange(swiper) {
            currentPage.value = swiper.realIndex + 1;
          },
        },
      });
      swiperText.value.controller.control = swiperMedia.value;
      swiperMedia.value.controller.control = swiperText.value;
    });

    return {
      tpl_carouselMedia,
      tpl_carouselText,
      swiperMedia,
      swiperText,
      currentPage,
      items,
    };
  },
};


import { ref, onMounted, nextTick } from "vue";

import { useRoute, useRouter } from "@nuxtjs/composition-api";
import { templateRef } from "@vueuse/core";
import { useDataLayer } from "/composables/useDataLayer.js";

export default {
  props: {
    dataProvider: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const tpl_carousel = templateRef("tpl_carousel");
    const swiperCarousel = ref(null);
    const route = useRoute();
    const router = useRouter();
    const { pushDataLayer } = useDataLayer();

    onMounted(() => {
      window.blockCarouselLauncherHandleClick = function (target) {
        const collectionItem =
          props.dataProvider.items[
            parseInt(target.getAttribute("data-swiper-slide-index"))
          ];
        if (route.value.name === "locale") {
          pushDataLayer({
            event: "click_carousel",
            collection_name: collectionItem.to.split("/")[2],
          });
        }
        router.push(`/${route.value.params.locale}${collectionItem.to}`);
      };

      swiperCarousel.value = new Swiper(tpl_carousel.value, {
        autoplay: {
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        },
        loop: props.dataProvider.items.length > 3,
        allowTouchMove: true,
        grabCursor: true,
        initialSlide:
          props.dataProvider.items.length > 3
            ? props.dataProvider.items.length
            : 0,
        roundLengths: true,
        threshold: 3,
        centeredSlides: true,
        spaceBetween: 24,
        slidesPerView: 1.5,
        speed: 800,
        roundLengths: true,
        breakpoints: {
          720: {
            spaceBetween: 56,
            slidesPerView: 1.7,
          },
          964: {
            spaceBetween: 64,
            slidesPerView: 2.3,
          },
          1248: {
            spaceBetween: 80,
            slidesPerView: 2.3,
          },
          1512: {
            spaceBetween: 104,
            slidesPerView: 2.3,
          },
          1920: {
            spaceBetween: 120,
            slidesPerView: 2.3,
          },
        },
      });

      nextTick(() => {
        if (swiperCarousel.value) swiperCarousel.value.update();
      });
    });

    return {
      tpl_carousel,
      swiperCarousel,
    };
  },
};
